import React from 'react'

import './MoreTagIcon.css'

export const MoreTagIcon = () => (
  <svg className='mt-icon' viewBox='0 0 612 612'>
    <path d='M561 76.5H178.5c-17.85 0-30.6 10.2-40.8 22.95L0 306l137.7 206.55c10.2 12.75 25.5 22.95 43.35 22.95H561c28.05 0 51-22.95 51-51v-357c0-28.05-22.95-51-51-51zM229.5 344.25c-20.4 0-38.25-17.85-38.25-38.25s17.85-38.25 38.25-38.25 38.25 17.85 38.25 38.25-17.85 38.25-38.25 38.25zm127.5 0c-20.4 0-38.25-17.85-38.25-38.25s17.85-38.25 38.25-38.25 38.25 17.85 38.25 38.25-17.85 38.25-38.25 38.25zm127.5 0c-20.4 0-38.25-17.85-38.25-38.25s17.85-38.25 38.25-38.25 38.25 17.85 38.25 38.25-17.85 38.25-38.25 38.25z' />
  </svg>
)

export default MoreTagIcon
