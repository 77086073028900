import React from 'react'

import './VinylIcon.css'

export const VinylIcon = () => (
  <div className='vinyl-icon'>
    <svg className='vinyl-icon__svg' viewBox='0 0 55.334 55.334'>
      <g>
        <circle cx='27.667' cy='27.667' r='3.618' />
        <path d='M27.667 0C12.387 0 0 12.387 0 27.667s12.387 27.667 27.667 27.667 27.667-12.387 27.667-27.667S42.947 0 27.667 0zM17.118 6.881c3.167-1.61 6.752-2.518 10.549-2.518.223 0 .444.003.665.009.367.01.619.922.564 2.025l-.282 5.677c-.055 1.103-.289 1.986-.523 1.979-.141-.004-.282-.006-.424-.006-1.997 0-3.894.43-5.603 1.202-1.007.455-2.212.184-2.774-.767l-2.896-4.897c-.562-.951-.261-2.203.724-2.704zm-1.132 10.414l-4.278-3.742c-.832-.727-.918-1.994-.119-2.756.019-.018.037-.035.057-.053.802-.76 2.059-.605 2.737.266l3.494 4.484c.679.871.837 1.889.391 2.314-.447.427-1.45.214-2.282-.513zm1.891 10.372c0-5.407 4.383-9.79 9.79-9.79s9.79 4.383 9.79 9.79-4.383 9.79-9.79 9.79-9.79-4.383-9.79-9.79zM38.17 48.476c-3.156 1.596-6.725 2.495-10.503 2.495-.248 0-.495-.004-.741-.011-.409-.013-.692-.929-.632-2.032l.31-5.676c.061-1.103.322-1.981.586-1.972.158.005.317.008.477.008 1.834 0 3.582-.362 5.179-1.018 1.022-.42 2.275-.144 2.877.782l3.101 4.77c.602.925.332 2.155-.654 2.654zm5.449-3.82c-.766.72-2.005.551-2.703-.305l-3.59-4.407c-.698-.856-.876-1.848-.435-2.255.442-.407 1.443-.179 2.274.549l4.28 3.744c.832.727.941 1.954.174 2.674z' />
      </g>
    </svg>
  </div>
)

export default VinylIcon
