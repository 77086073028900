import React from 'react'

import './SIcon.css'

export const SIcon = () => (
  <svg className='s-icon' viewBox='0 0 512 512'>
    <path d='M253 103.7c-9.3 1.2-23.8 4.6-33.1 7.9-47.5 16.5-74 50.3-68.9 88 2.1 15.9 9.3 29.9 20.9 40.7 11.1 10.2 20.1 13.9 92.1 37.8 48.7 16.1 66.2 22.3 68.3 24.2 2.1 1.9 2.7 3.4 2.7 6.6 0 2.3-.7 5.5-1.5 7-2 4-10.7 11.5-18.4 16.1-23.4 13.8-59.1 14.6-84.4 1.9-9.7-4.9-15.3-9-27.2-19.8-11.6-10.6-16.2-13.1-24-13.1-11 0-21.3 6.6-25.5 16.2-1.9 4.4-2.2 6.5-1.8 13.3.5 10.5 2.7 14.2 14.6 25.7 22.4 21.6 46.8 34.6 75.8 40.5 13.7 2.7 45.3 2.5 59.1-.5 45.7-9.9 79.8-36.3 87.9-68.2 2.3-8.7 2.2-25.4-.1-34-4.3-16.2-15.5-31.7-28.6-39.4-11.2-6.7-22.7-10.9-85.4-31.5-72.1-23.7-69.1-22.3-68.3-31.6.5-5.9 2-8.2 9.7-14.7 19.8-16.8 54.7-22.6 82.4-13.7 14.4 4.7 23.7 10.6 40.1 25.7 10.5 9.7 15.3 12.2 23.1 12.2 10.1 0 19.1-5.1 24.3-13.8 2.4-4.2 2.7-5.6 2.7-14.2 0-12.1-.8-13.6-13.9-26.5-21.7-21.3-45.9-34.7-73.1-40.5-7.6-1.6-14.4-2.2-28-2.5-9.9-.2-19.6-.1-21.5.2z' />
  </svg>
)

export default SIcon
