import React from 'react'

import './HeartIcon.css'

export const HeartIcon = () => (
  <svg className='heart-icon' viewBox='0 0 645 585'>
    <path d='M297.297 550.868c-13.775-15.436-48.17-45.53-76.434-66.874C137.12 420.752 125.72 411.6 91.72 380.29 29.034 322.57 2.413 264.58 2.504 185.95c.044-38.383 2.66-53.17 13.41-75.796C34.15 71.768 61.015 43.244 95.36 25.8c24.325-12.356 36.323-17.846 76.944-18.07 42.494-.235 51.44 4.72 76.435 18.45 30.424 16.716 61.74 52.437 68.212 77.812l4 15.672 9.858-21.584c55.716-121.973 233.6-120.148 295.502 3.032 19.638 39.076 21.794 122.513 4.38 169.512-22.715 61.31-65.38 108.05-164.006 179.677-64.68  46.975-137.885 118.047-142.98 128.03-5.916 11.587-.283 1.815-26.41-27.462z' />
  </svg>
)

export default HeartIcon
